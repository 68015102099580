import { React, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { checkAll, checkedArticleDelete } from "../utils/form";
import { utilConvertToMillis, getAccessToken, replaceAll } from "../utils/common";
import { useRecoilState } from "recoil";
import { toastState, loadingState } from "../utils/atom";
import axios from "axios";
import Layout from "../components/Layout";
import Pager from "../components/Pager";
import SearchForm from "../components/SearchForm";
import RecipeDetail_V4 from "./RecipeDetail_V4";

const RecipeList_V4 = () => {
    const [toast, setToast] = useRecoilState(toastState);
    const [loading, setLoading] = useRecoilState(loadingState);
    const [searchParams, setSearchParams] = useSearchParams();
    const idx = searchParams.get("idx") ?? null;
    const page = searchParams.get("page") ?? "1";
    const q = searchParams.get("q") ?? "";

    const [data, setData] = useState([]);
    const [pageHelper, setPageHelper] = useState({});

    useEffect(() => {
        fetch();
    }, [page, q]);

    async function fetch() {
        setLoading(true);
        const url = `${process.env.REACT_APP_HOST}/v4adm/recipe?page=${page}&q=${q}`;
        const { data } = await axios({
            url,
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });

        if (data) {
            setData(data.list);
            setPageHelper(data.pageHelper);
        }
        setLoading(false);
    }

    const handleDelete = async (e) => {
        e.preventDefault();
        if (await checkedArticleDelete(e, "V4_RECIPE_tbl")) {
            await fetch();
        }
        checkAll(false);
    };

    function getThumbnail(url) {
        if (url.includes("cloudinary")) {
            const tmp = url.split("/upload");
            const thumb = tmp[0] + "/upload/w_100,h_100,c_fill" + tmp[1];
            return thumb;
        }
        return url;
    }

    function modalOpen(idx) {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            idx,
        });
    }

    function modalClose(isRefresh) {
        const params = Object.fromEntries(searchParams.entries());
        if (isRefresh) {
            fetch();
        }
        delete params.idx;
        setSearchParams(params);
    }

    return (
        <Layout>
            <div className="row">
                <div className="col-12 mb-5">
                    <div className="table-responsive rounded bg-white p-3 shadow">
                        <SearchForm />
                        <form onSubmit={handleDelete}>
                            <table className="table table-striped">
                                <tbody>
                                    <tr>
                                        <th className="text-center text-nowrap" width={50}>
                                            <input type="checkbox" onClick={(e) => checkAll(e.target.checked)} />
                                        </th>
                                        <th className="text-center text-nowrap" width={50}>
                                            Idx.
                                        </th>
                                        <th className="text-center text-nowrap"></th>
                                        <th className="text-center text-nowrap">제목</th>
                                        <th className="text-center text-nowrap">카테고리</th>
                                        <th className="text-center text-nowrap">태그</th>
                                        <th className="text-center text-nowrap">생성일</th>
                                        <th className="text-center text-nowrap">수정일</th>
                                    </tr>
                                    {data.map((row, i) => (
                                        <tr key={row.idx}>
                                            <td className="text-center text-nowrap">
                                                <input type="checkbox" name="idx" value={row.idx} />
                                            </td>
                                            <td className="text-center text-nowrap">{row.idx}</td>
                                            <td className="text-center p-0">
                                                {row.filename0 && (
                                                    <img
                                                        src={getThumbnail(row.filename0)}
                                                        alt=""
                                                        width={38}
                                                        height={38}
                                                    />
                                                )}
                                            </td>
                                            <td>
                                                <a
                                                    href="#"
                                                    className="hand link-underline text-nowrap"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        modalOpen(row.idx);
                                                    }}
                                                >
                                                    {row.title}
                                                </a>
                                            </td>
                                            <td className="text-center text-nowrap">{row.category}</td>
                                            <td className="text-center text-nowrap">{row.tags}</td>
                                            <td className="text-center text-nowrap">
                                                {utilConvertToMillis(row.created)}
                                            </td>
                                            <td className="text-center text-nowrap">
                                                {utilConvertToMillis(row.modified)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="d-flex justify-content-between">
                                <button type="submit" className="btn btn-link btn-sm text-danger">
                                    삭제
                                </button>

                                <Link to="/v4/adm/recipe?idx=add" className="btn btn-link btn-sm">
                                    등록
                                </Link>
                            </div>
                        </form>
                    </div>
                    <br />
                    <Pager pageHelper={pageHelper} />
                </div>
            </div>

            {idx && <RecipeDetail_V4 modalClose={modalClose} idx={idx} />}
        </Layout>
    );
};

export default RecipeList_V4;
