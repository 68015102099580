import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "./css/bootstrap.icons.css";
import "./css/admin.css";

import { React } from "react";
import { useRecoilState } from "recoil";
import { toastState, loadingState } from "./utils/atom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { getUser } from "./utils/common";
import axios from "axios";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Index from "./pages/Index";
import Main from "./pages/Main";
import Jaelyo from "./pages/Jaelyo";
import Category from "./pages/Category";
import RcpList from "./pages/RcpList";
import LastRcpData from "./pages/LastRcpData";
import ImageChangeTest from "./pages/ImageChangeTest";
import Toast from "./components/Toast";
import Loading from "./components/Loading";
import RecipeList_V4 from "./pages/RecipeList_V4";
import LastRcpData_V4 from "./pages/LastRcpData_V4";
import Category_V4 from "./pages/Category_V4";

console.log(process.env.NODE_ENV);

if (process.env.NODE_ENV === "production") {
    console.log = function no_console() {};
    console.warn = function no_console() {};
}

// 요청 인터셉터 추가하기
axios.interceptors.request.use(
    async (config) => {
        // console.log(config);
        // await fetch("/token/setter", { method: "POST" });
        // const { token } = await fetch("http://localhost:3000/token/setter", { method: "GET" }).then((res) => res.json());
        // config.headers.token = `${token}`;
        return config;
    },
    (error) => {
        // return Promise.reject(error);
    }
);

const App = () => {
    const [loading, setLoading] = useRecoilState(loadingState);
    const [toast, setToast] = useRecoilState(toastState);

    const user = getUser();

    if (user) {
        return (
            <>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Index />}></Route>
                        <Route path="*" element={<NotFound />}></Route>
                        <Route path="/v3/adm" element={<Main />}></Route>
                        <Route path="/v3/adm/login" element={<Login />}></Route>
                        <Route path="/v3/adm/rcp" element={<RcpList />}></Route>
                        <Route path="/v3/adm/category" element={<Category />}></Route>
                        <Route path="/v3/adm/jaelyo" element={<Jaelyo />}></Route>
                        <Route path="/v3/adm/last_rcp_data" element={<LastRcpData />}></Route>
                        <Route path="/v3/adm/image_change_test" element={<ImageChangeTest />}></Route>

                        <Route path="/v4/adm/recipe" element={<RecipeList_V4 />}></Route>
                        <Route path="/v4/adm/category" element={<Category_V4 />}></Route>
                        <Route path="/v4/adm/last_rcp_data" element={<LastRcpData_V4 />}></Route>
                    </Routes>
                </BrowserRouter>
                {loading && <Loading />}
                {toast.isToast && <Toast />}
            </>
        );
    } else {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Index />}></Route>
                    <Route path="/v3/adm/login" element={<Login />}></Route>
                    <Route path="*" element={<NotFound />}></Route>
                </Routes>
            </BrowserRouter>
        );
    }
};

export default App;
