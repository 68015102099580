import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { getAccessToken, replaceAll } from "../utils/common";
import { toastState, loadingState } from "../utils/atom";
import axios from "axios";
import KyhImageDropUploader from "../components/KyhImageDropUploader";
import KyhSingleFileUploader from "../components/KyhSingleFileUploader";
import RecipeStep_V4 from "./RecipeStep_V4";
import RecipeIngredients_V4 from "./RecipeIngredients_V4";
// import OpenAIPopup_V4 from "./OpenAIPopup_V4";
import TextViewPopup_V4 from "./TextViewPopup_V4";
import JsonWritePopup_V4 from "./JsonWritePopup_V4";

const RecipeDetail_V4 = ({ modalClose, idx }) => {
    const [toast, setToast] = useRecoilState(toastState);
    const [loading, setLoading] = useRecoilState(loadingState);
    const [categorys, setCategorys] = useState([]);
    const [popup, setPopup] = useState(false);
    const [popup2, setPopup2] = useState(false);
    const [popup3, setPopup3] = useState(false);
    const [isModifiedUpdate, setModifiedUpdate] = useState(false);

    // const [title, setTitle] = useState("");
    // const [filename0, setFilename0] = useState("");
    // const [category, setCategory] = useState("");
    // const [tags, setTags] = useState("");
    // const [writer, setWriter] = useState("최고의요리비결");
    // const [source, setSource] = useState("");

    const defaultStepRow = {
        title: "",
        instruction: "",
        tips: "",
        filename0: "",
        filename1: "",
        filename2: "",
        filename3: "",
        filename4: "",
        filename5: "",
        filename6: "",
        filename7: "",
        filename8: "",
        filename9: "",
    };
    const defaultIngredientRow = { item: "", capacity: "" };

    const [row, setRow] = useState({
        title: "",
        soge: "\n2인분",
        servings: "",
        difficulty: "",
        cooking_time: "",
        category: "",
        tags: "",
        writer: "최고의요리비결",
        source: "",
        ingredients: {
            main: [defaultIngredientRow],
            seasoning: [defaultIngredientRow],
            etc: [defaultIngredientRow],
        },
        steps: [defaultStepRow],
    });

    useEffect(() => {
        (async () => {
            if (idx !== "add") {
                await fetch();
            }
            await getCategorys();
        })();
    }, [idx]);

    async function fetch() {
        const url = `${process.env.REACT_APP_HOST}/v4adm/recipe/${idx}`;
        const { data } = await axios({
            url,
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        if (data) {
            const obj = {
                title: data.title,
                filename0: data.filename0,
                category: data.category,
                tags: data.tags,
                writer: data.writer || "최고의요리비결",
                source: data.source,
            };

            try {
                const json = JSON.parse(data.json_data);
                for (const o of json.steps) {
                    delete o.step_number;
                }
                // console.log(json);

                const obj2 = {
                    ...obj,
                    soge: json.soge,
                    servings: json.servings,
                    difficulty: json.difficulty,
                    cooking_time: json.cooking_time,
                    ingredients: json.ingredients,
                    steps: json.steps,
                };
                setRow(obj2);
                console.log(obj2);
            } catch (e) {
                console.log(e);
            }
        }
    }

    async function getCategorys() {
        const url = `${process.env.REACT_APP_HOST}/v4adm/categorys`;
        const { data } = await axios({
            url,
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        if (data) {
            setCategorys(data);
        }
    }

    const getImageWidth = (imageUrl) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = imageUrl;

            img.onload = () => {
                resolve(img.width); // 이미지의 가로 크기를 반환
            };

            img.onerror = (error) => {
                reject(error); // 이미지 로드에 실패하면 에러 반환
            };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // created, modified 제거!
        delete row.created;
        delete row.modified;

        const frm = {};

        if (idx) {
            if (idx !== "add") {
                frm.idx = idx;
            }
        }

        // filename0 가로 크키 구하기!
        if (row.filename0) {
            frm.width = await getImageWidth(row.filename0);
        } else {
            frm.width = 0;
        }
        frm.table = "V4_RECIPE_tbl";
        frm.title = row.title;
        frm.filename0 = row.filename0;
        frm.category = row.category;
        frm.tags = row.tags;
        frm.writer = row.writer;
        frm.source = row.source;
        frm.is_modified_update = isModifiedUpdate ? 1 : 0;
        console.log("frm", frm);
        const json_str = JSON.stringify(row);

        // 임시로 json_data 저장
        await axios({
            url: `${process.env.REACT_APP_HOST}/v3crud/write`,
            method: "POST",
            data: {
                table: "V4_RCP_TEMP_tbl",
                idx: "1",
                json_data: json_str,
            },
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        //

        frm.json_data = json_str;

        console.log("frm", frm);

        const { data } = await axios({
            url: `${process.env.REACT_APP_HOST}/v3crud/write`,
            method: "POST",
            data: frm,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        console.log(data);

        setLoading(false);

        setToast({
            isToast: true,
            message: "저장 되었습니다.",
        });

        modalClose(true);
    };

    function addRow(i, direction) {
        const steps = [...row.steps];
        if (direction === "up") {
            steps.splice(i, 0, defaultStepRow);
        } else {
            steps.splice(i + 1, 0, defaultStepRow);
        }
        setTimeout(() => {
            setRow({ ...row, steps });
        }, 300);
    }

    function removeRow(i) {
        const steps = [...row.steps];
        if (i === 0 && steps.length === 1) {
            window.alert("첫번째 행은 삭제할 수 없습니다.");
            return;
        }

        steps.splice(i, 1);
        setRow({ ...row, steps });
    }

    const handleSingleCheck = (e) => {
        setModifiedUpdate(e.target.checked);
    };

    return (
        <div className="position-absolute top-0 start-0 bg-light w-100" style={{ zIndex: "999", display: "block" }}>
            <form onSubmit={handleSubmit}>
                <div className="d-flex justify-content-between border-bottom py-3 mb-2 bg-white">
                    <div className="d-flex flex-row align-items-center">
                        <button
                            type="submit"
                            className={`btn btn-${idx === "add" ? "primary" : "success"} btn-sm ms-2`}
                        >
                            {idx === "add" ? "Add" : "Modify"}
                        </button>

                        <div className="form-check ms-4">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexCheckDefault"
                                checked={isModifiedUpdate}
                                onChange={(e) => setModifiedUpdate(e.target.checked)}
                            />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                최상위로
                            </label>
                        </div>
                    </div>
                    <button type="button" className="btn-close me-1" onClick={() => modalClose(false)}></button>
                </div>
                <div className="d-flex flex-row">
                    <button type="button" className="btn btn-sm btn-link" onClick={() => setPopup(true)}>
                        텍스트로보기
                    </button>
                    <button type="button" className="btn btn-sm btn-link" onClick={() => setPopup2(true)}>
                        json
                    </button>
                </div>

                <input type="hidden" name="idx" readOnly value={idx} />

                <div className="bg-white p-2 mt-2 mb-2 border-top border-bottom">
                    <div className="d-flex flex-row">
                        <div style={{ width: "100px" }}>
                            <div style={{ height: "100px" }}>
                                <KyhImageDropUploader
                                    filename={row.filename0 || ""}
                                    onResult={(url) => setRow({ ...row, filename0: url })}
                                />
                            </div>
                            <input
                                type="text"
                                name="filename0"
                                className="form-control form-control-sm mt-1 mb-1"
                                value={row.filename0 || ""}
                                onChange={(e) => setRow({ ...row, filename0: e.target.value })}
                            />
                            <KyhSingleFileUploader onResult={(url) => setRow({ ...row, filename0: url })} />
                        </div>

                        <div className="d-flex flex-column flex-fill ms-1">
                            <div className="form-floating">
                                <input
                                    type="text"
                                    name="title"
                                    className="form-control form-control-sm"
                                    placeholder="제목"
                                    value={row.title || ""}
                                    onChange={(e) => {
                                        setRow({ ...row, title: e.target.value });
                                    }}
                                    required
                                />
                                <label>제목</label>
                            </div>

                            <div className="form-floating">
                                <textarea
                                    name="soge"
                                    className="form-control form-control-sm mt-1"
                                    placeholder="소개글"
                                    style={{ height: "100px" }}
                                    value={row.soge || ""}
                                    onChange={(e) => setRow({ ...row, soge: e.target.value })}
                                    required
                                />
                                <label>소개글</label>
                            </div>

                            <div className="form-floating">
                                <input
                                    type="text"
                                    name="servings"
                                    className="form-control form-control-sm mt-1"
                                    placeholder="인분"
                                    value={row.servings || ""}
                                    onChange={(e) => setRow({ ...row, servings: e.target.value })}
                                />
                                <label>인분</label>
                            </div>

                            <div className="form-floating">
                                <input
                                    type="text"
                                    name="cooking_time"
                                    className="form-control form-control-sm mt-1"
                                    placeholder="조리시간"
                                    value={row.cooking_time || ""}
                                    onChange={(e) => setRow({ ...row, cooking_time: e.target.value })}
                                />
                                <label>조리시간</label>
                            </div>

                            <div className="form-floating">
                                <input
                                    type="text"
                                    name="difficulty"
                                    className="form-control form-control-sm mt-1"
                                    placeholder="난이도"
                                    value={row.difficulty || ""}
                                    onChange={(e) => setRow({ ...row, difficulty: e.target.value })}
                                />
                                <label>난이도</label>
                            </div>
                            <div className="form-floating">
                                <input
                                    list="categorys_option"
                                    type="text"
                                    name="category"
                                    className="form-control form-control-sm mt-1"
                                    placeholder="카테고리"
                                    value={row.category || ""}
                                    onChange={(e) => setRow({ ...row, category: e.target.value })}
                                />
                                <label>카테고리</label>
                            </div>
                            <datalist id="categorys_option">
                                {categorys.map((category, i) => (
                                    <option key={i} value={category} />
                                ))}
                            </datalist>

                            <div className="form-floating">
                                <input
                                    type="text"
                                    name="tags"
                                    className="form-control form-control-sm mt-1"
                                    placeholder="태그"
                                    value={row.tags || ""}
                                    onChange={(e) => setRow({ ...row, tags: e.target.value })}
                                    onBlur={() => {
                                        const tmp = row.tags;
                                        const newTag = replaceAll(tmp, " ", "");
                                        setRow({ ...row, tags: newTag });
                                    }}
                                />
                                <label>태그</label>
                            </div>

                            <div className="form-floating">
                                <input
                                    type="text"
                                    name="writer"
                                    className="form-control form-control-sm mt-1"
                                    placeholder="작성자"
                                    value={row.writer}
                                    onChange={(e) => setRow({ ...row, writer: e.target.value })}
                                />
                                <label>작성자</label>
                            </div>
                            <div className="form-floating">
                                <input
                                    type="text"
                                    name="source"
                                    className="form-control form-control-sm mt-1"
                                    placeholder="출처링크"
                                    value={row.source || ""}
                                    onChange={(e) => setRow({ ...row, source: e.target.value })}
                                />
                                <label>출처링크</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white p-2 mt-2 mb-2 border-top border-bottom">
                    <div className="fw-bold mt-4">재료</div>
                    <RecipeIngredients_V4 gbn="main" row={row} setRow={setRow} />

                    <div className="fw-bold mt-4">양념</div>
                    <RecipeIngredients_V4 gbn="seasoning" row={row} setRow={setRow} />

                    <div className="fw-bold mt-4">기타</div>
                    <RecipeIngredients_V4 gbn="etc" row={row} setRow={setRow} />
                </div>

                {row.steps.map((step, i) => (
                    <div key={`step_${i}`} className="mb-4 bg-white border-top border-bottom">
                        <div className="d-flex flex-row justify-content-between">
                            <button
                                id={`step${i + 1}`}
                                type="button"
                                className="btn btn-link text-danger"
                                style={{ fontSize: "10px" }}
                                onClick={() => addRow(i, "up")}
                            >
                                <i className="bi bi-arrow-up"></i>위에 행추가
                            </button>
                            <button type="button" className="btn btn-link text-danger" onClick={() => removeRow(i)}>
                                <i className="bi bi-dash-circle-fill"></i>
                            </button>
                        </div>

                        <RecipeStep_V4 step={step} i={i} row={row} setRow={setRow} />

                        <button
                            type="button"
                            className="btn btn-link ps-0"
                            style={{ fontSize: "10px" }}
                            onClick={() => addRow(i, "down")}
                        >
                            <i className="bi bi-arrow-down"></i>아래에 행추가
                        </button>
                    </div>
                ))}
            </form>

            {popup && <TextViewPopup_V4 row={row} onClose={() => setPopup(false)} />}
            {popup2 && (
                <JsonWritePopup_V4
                    onResult={(json) => {
                        setPopup2(false);
                        if (json != "") {
                            // 파일 url 추출
                            const oldFileArr = [];
                            for (const o of row.steps) {
                                const arr = [];
                                for (let j = 0; j <= 9; j++) {
                                    if (o[`filename${j}`]) {
                                        arr.push(o[`filename${j}`]);
                                    }
                                }
                                oldFileArr.push(arr);
                            }

                            try {
                                const tmp = JSON.parse(json);

                                // tmp.steps 안에 oldFileArr의 파일명을 filename0 ~ filename9로 넣기
                                tmp.steps.forEach((step, index) => {
                                    const fileArr = oldFileArr[index] || [];
                                    fileArr.forEach((filename, idx) => {
                                        if (idx <= 9) {
                                            step[`filename${idx}`] = filename;
                                        }
                                    });
                                });

                                console.log(tmp);

                                setRow({
                                    ...tmp,
                                    filename0: row.filename0,
                                    writer: row.writer,
                                    source: row.source,
                                });
                            } catch (e) {
                                console.log(e.message);
                            }
                        }
                    }}
                />
            )}
        </div>
    );
};

export default RecipeDetail_V4;
