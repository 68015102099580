const menu = [
    {
        icon: "bi-layout-text-window-reverse",
        title: "V4",
        child: [
            {
                title: "레시피관리",
                link: "/v4/adm/recipe",
            },
            {
                title: "카테고리관리",
                link: "/v4/adm/category",
            },
            {
                title: "마지막저장데이터",
                link: "/v4/adm/last_rcp_data",
            },
        ],
    },
    {
        icon: "bi-layout-text-window-reverse",
        title: "V3",
        child: [
            {
                title: "레시피관리",
                link: "/v3/adm/rcp",
            },
            {
                title: "재료관리",
                link: "/v3/adm/jaelyo",
            },
            {
                title: "카테고리관리",
                link: "/v3/adm/category",
            },
            {
                title: "마지막저장데이터",
                link: "/v3/adm/last_rcp_data",
            },
            {
                title: "이미지변형태스트",
                link: "/v3/adm/image_change_test",
            },
        ],
    },

];
module.exports = menu;

