import { useState } from "react";

const JsonWritePopup_V4 = ({ onResult }) => {
    const [json, setJson] = useState("");

    const handleOnClose = () => {
        onResult("");
    };

    const handleOnResult = () => {
        onResult(json);
    };

    return (
        <div className="modal bg-dark bg-opacity-50" style={{ display: "block" }} id="backdrop">
            <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header py-3">
                        <button type="button" className="btn btn-sm btn-light py-0" onClick={handleOnResult}>
                            적용
                        </button>
                        <button type="button" className="btn-close" onClick={handleOnClose}></button>
                    </div>
                    <div className="modal-body p-0">
                        <textarea
                            style={{ height: "400px" }}
                            className="form-control form-control-sm border-0"
                            value={json}
                            onChange={(e) => setJson(e.target.value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JsonWritePopup_V4;
