import React, { useState, useEffect } from "react";
import { getAccessToken } from "../utils/common";
import axios from "axios";

export default ({ filename, onResult }) => {
    const [isDragEnter, setDragEnter] = useState(false);
    const [image, setImage] = useState("/images/no-img2.png");
    const [isShow, setShowImage] = useState(false);
    const [isUploading, setUploading] = useState(false);

    useEffect(() => {
        if (filename) {
            setImage(filename);
        }
    }, [filename]);

    const dragEnter = (e) => {
        e.preventDefault();
        setDragEnter(true);
    };
    const dragLeave = (e) => {
        e.preventDefault();
        setDragEnter(false);
    };
    const dragOver = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const drop = async (e) => {
        setDragEnter(false);
        setShowImage(false);
        setUploading(true);
        e.preventDefault();

        let file = null;

        try {
            // 이미지 URL 가져오기
            const imageUrl = e.dataTransfer.getData("text/html");
            const urlLink = extractImageUrl(imageUrl);

            if (urlLink) {
                const imgData = await getImageData(urlLink);
                file = base64ToFile(imgData.base64, imgData.filename);
            } else {
                // 로컬 파일 업로드
                const files = [...e.dataTransfer.files];
                if (files.length > 0) {
                    file = files[0];
                }
            }
        } catch (error) {
            console.error("Error handling drop event:", error);
        }

        const url = await cloudinaryUpload(file);
        // const url = await cloudinaryUploadForNodeServer(file);
        if (url === "" || url === null) {
            setImage("");
        } else {
            setImage(url);
        }
        setUploading(false);
        onResult(url);
    };

    /**
     * 클라우디나리 업로드 다이렉트로 업로드
     */
    const cloudinaryUpload = async (file) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "mzbjpjdi");
        formData.append("folder", "rcp_v4");
        const { data } = await axios({
            url: `https://api.cloudinary.com/v1_1/dse98rb39/image/upload`,
            method: "POST",
            data: formData,
        });
        return data.secure_url;
    };

    /**
     * 클라우디나리 업로드 서버 거쳐서 업로드
     */
    const cloudinaryUploadForNodeServer = async (file) => {
        // multer를 이용해서 올리므로 filename0 키값 필요.
        const formData = new FormData();
        formData.append("filename0", file);
        const { data } = await axios({
            url: `${process.env.REACT_APP_HOST}/upload/cloudinary`,
            method: "POST",
            data: formData,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        console.log(data);
        const url = data[0].value;
        return url;
    };

    const deleteImage = () => {
        if (window.confirm("삭제 하시겠습니까?")) {
            const arr = [];
            arr.push(image);

            axios({
                url: `${process.env.REACT_APP_HOST}/upload/delete_images`,
                method: "POST",
                data: {
                    delete_images: arr,
                },
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: `Bearer ${getAccessToken()}`,
                },
            });
            //
            setImage("/images/no-img2.png");
            onResult("");
        }
    };

    // 이미지 URL 추출 함수
    function extractImageUrl(html) {
        try {
            const rex = /src=["']?([^"'\s>]+)["']?/;
            const match = rex.exec(html);
            return match ? match[1] : "";
        } catch (error) {
            console.error("Error extracting image URL:", error);
            return "";
        }
    }

    // 이미지 데이터 가져오기 함수
    async function getImageData(urlLink) {
        try {
            if (urlLink.includes("base64")) {
                return { base64: urlLink, filename: "image.png" }; // 파일 이름은 필요에 따라 조정
            } else {
                const result = await axios({
                    url: `${process.env.REACT_APP_HOST}/upload/get_base64`,
                    method: "POST",
                    data: { url_link: urlLink },
                });
                return result.data;
            }
        } catch (error) {
            console.error("Error fetching image data:", error);
            throw error;
        }
    }

    // Base64를 파일로 변환하는 함수
    function base64ToFile(base64Data, filename) {
        try {
            const [metadata, content] = base64Data.split(",");
            const mime = metadata.match(/:(.*?);/)[1];
            const bstr = atob(content);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        } catch (error) {
            console.error("Error converting base64 to file:", error);
            return null;
        }
    }

    const handleNewBrowserOpen = () => {
        if (image != "/images/no-img2.png") {
            window.open(image, "_blank"); // 새 창에서 이미지 열기
        }
    };

    return (
        <>
            <div
                className="position-relative w-100 h-100"
                onDragEnter={(e) => dragEnter(e)}
                onDragLeave={(e) => dragLeave(e)}
                onDragOver={(e) => dragOver(e)}
                onDrop={(e) => drop(e)}
            >
                <img
                    src={image}
                    style={{ objectFit: "cover" }}
                    className={`${isDragEnter ? "bg-dark" : "bg-white"} border rounded w-100 h-100`}
                    onClick={handleNewBrowserOpen}
                />

                {image !== "/images/no-img2.png" && (
                    <img
                        src={"/images/photo_x.png"}
                        className="position-absolute"
                        style={{ cursor: "pointer", top: "4px", right: "4px" }}
                        width={15}
                        height={15}
                        onClick={deleteImage}
                    />
                )}

                {isUploading && (
                    <div className="position-absolute top-50 start-50 translate-middle">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
