import React, { useState, useEffect } from "react";
import KyhMultiFileUploader from "../components/KyhMultiFileUploader";
import KyhImageDropUploader from "../components/KyhImageDropUploader";

const RecipeStep_V4 = ({ step, i, row, setRow }) => {
    const handleUploadResult = (urls, index) => {
        console.log("handleUploadResult", urls, index);

        for (var j in urls) {
            const steps = [...row.steps];
            steps[index][`filename${j}`] = urls[j];
            setRow({ ...row, steps });
        }
    };

    const handleUploadResultDnD = (url, index) => {
        console.log("handleUploadResultDnD", index);
        const steps = [...row.steps];
        steps[i][`filename${index}`] = url;
        setRow({ ...row, steps });
    };

    return (
        <div className="bg-white p-2 my-0">
            <div className="d-flex flex-row">
                <h3 className="mb-0 me-2">{i + 1}.</h3>
                <KyhMultiFileUploader onResult={(urls) => handleUploadResult(urls, i)} />
            </div>
            <div className="d-flex flex-row overflow-x-auto">
                {new Array(10).fill().map((_, j) => (
                    <div key={`img_${j}`} className="me-1">
                        <div style={{ width: "60px", height: "60px" }}>
                            <KyhImageDropUploader
                                filename={step[`filename${j}`]}
                                onResult={(url) => handleUploadResultDnD(url, j)}
                            />
                        </div>
                        <input
                            type="text"
                            name={`filename${j}`}
                            className="my-1 border"
                            style={{ width: "60px", height: "24px", fontSize: "12px" }}
                            value={step[`filename${j}`] || ""}
                            onChange={(e) => {
                                const steps = [...row.steps];
                                steps[i][`filename${j}`] = e.target.value;
                                setRow({ ...row, steps });
                            }}
                        />
                    </div>
                ))}
            </div>
            <div className="d-flex flex-column">
                <input
                    type="text"
                    className="form-control form-control-sm mt-1"
                    value={step.title}
                    onChange={(e) => {
                        const steps = [...row.steps];
                        steps[i].title = e.target.value;
                        setRow({ ...row, steps });
                    }}
                />

                <textarea
                    className="form-control form-control-sm my-1"
                    style={{ height: "140px" }}
                    value={step.instruction}
                    onChange={(e) => {
                        const steps = [...row.steps];
                        steps[i].instruction = e.target.value;
                        setRow({ ...row, steps });
                    }}
                />
                <input
                    type="text"
                    className="form-control form-control-sm"
                    value={step.tips}
                    onChange={(e) => {
                        const steps = [...row.steps];
                        steps[i].tips = e.target.value;
                        setRow({ ...row, steps });
                    }}
                />
            </div>
        </div>
    );
};
export default RecipeStep_V4;
