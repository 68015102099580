import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { toastState, loadingState } from "../utils/atom";

const TextViewPopup_V4 = ({ row, onClose }) => {
    const [toast, setToast] = useRecoilState(toastState);
    const [loading, setLoading] = useRecoilState(loadingState);

    const [text, setText] = useState("");

    useEffect(() => {
        // 레시피 정보를 텍스트로 변환
        const recipeText = `
제목: ${row.title}
소개: ${row.soge}
인분: ${row.servings || ""}
난이도: ${row.difficulty || ""}
조리시간: ${row.cooking_time || ""}
카테고리: ${row.category || ""}
태그: ${row.tags || ""}
작성자: ${row.writer}
출처: ${row.source}

재료:
${Object.entries(row.ingredients)
    .map(([key, ingredients]) => `${ingredients.map((ing) => `${ing.item}: ${ing.capacity}`).join("\n")}`)
    .join("\n")}

조리 과정:
${row.steps
    .map((step, index) => `${index + 1}. ${step.title}\n   ${step.instruction}\n   팁: ${step.tips}`)
    .join("\n\n")}
        `.trim();

        setText(recipeText);
    }, [row]);

    const textCopy = () => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                setToast({
                    isToast: true,
                    message: "레시피가 클립보드에 복사되었습니다.",
                });
            })
            .catch((err) => {
                console.error("텍스트 복사 실패:", err);
                setToast({
                    isToast: true,
                    message: "레시피 복사에 실패했습니다.",
                });
            });
    };

    return (
        <div className="modal bg-dark bg-opacity-50" style={{ display: "block" }} id="backdrop">
            <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header py-3">
                        <button type="button" className="btn btn-sm btn-light py-0" onClick={textCopy}>
                            Copy
                        </button>
                        <button type="button" className="btn-close" onClick={onClose}></button>
                    </div>
                    <div className="modal-body p-2">
                        <pre style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>{text}</pre>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TextViewPopup_V4;
